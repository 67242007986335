import React from 'react'
import Button from '../Button/Button'
import './Presentation.css'
import { scrollToContactForm } from '../../Utils/Utils'
import LinkButton from '../LinkButton/LinkButton'

function Presentation({ title, texts, buttonText, className, href, handleClick, isLink }) {
  return (
    <div className={'presentation-main ' + className}>
      <h1  tabIndex={-1}>{title}</h1>

      {texts.map((text, index) => {
        return <text key={index}>{text}</text>
      })}
      {isLink ? (
        <LinkButton
          href={href}
          size="large"
          className="presentation-button"
          onClick={handleClick ? handleClick : scrollToContactForm}
        >
          {buttonText || 'Get a free consultation'}
        </LinkButton>
      ) : (
        <Button
          size="large"
          className="presentation-button"
          onClick={handleClick ? handleClick : scrollToContactForm}
        >
          {buttonText || 'Get a free consultation'}
        </Button>
      )}
    </div>
  )
}

export default Presentation
