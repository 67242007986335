import '../../Home/Home.css'
import { scrollToContactForm } from '../../Utils/Utils'
import BicolorTitledText from '../BicolorTitledText/BicolorTitledText'
import ToolList from '../ToolList/ToolList'

import './Toolset.css'
import LinkButton from '../LinkButton/LinkButton'

function Toolset() {
  return (
    <div className="toolset-container">
      <BicolorTitledText color1="black" color2="#0D9E71" color1Text="Our" color2Text="toolset">
        <p>With the tools below and beyond we ensure your product is ready to go out to the market.</p>

        <LinkButton size="large" className="toolset-link" onClick={scrollToContactForm}>
          Get a free consultation
        </LinkButton>
      </BicolorTitledText>
      {/* <div className="toolset-mobile-circle"></div> */}
      <div className="toolset-tablet-circle"></div>
      <div className="toolset-desktop-1-circle"></div>
      <div className="toolset-desktop-2-circle"></div>

      <ToolList></ToolList>
    </div>
  )
}

export default Toolset
