import React from 'react'
import CardButton from '../CardButton/CardButton.js'
import './VerticalCard.css'

function VerticalCard({ imgUrl, title, children, btnText, btnUrl, linkAriaLabel }) {
  let ariaLabelToAdd = { 'aria-label': linkAriaLabel }

  return (
    <div className="vertical-card">
      <img src={imgUrl} alt={title} />
      <h3 className="vertical-card-title">{title}</h3>

      <div className="card-content">{children}</div>
      <div className="vertical-card-container-button">
        <CardButton className="vertical-card-button card-button">
          <a
            className="no-decorator-link"
            href={btnUrl}
            target="_blank"
            rel="noreferrer"
            {...(linkAriaLabel && ariaLabelToAdd)}
          >
            {btnText}
          </a>
        </CardButton>
      </div>
    </div>
  )
}

export default VerticalCard
