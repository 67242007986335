import React from 'react'
import './PresentationSign.css'
import { scrollToContactForm } from '../../Utils/Utils'
import LinkButton from '../LinkButton/LinkButton'

function PresentationSign({ className }) {
  return (
    <div className={'presentation-sign ' + className}>
      <h1 tabIndex={-1} style={{ zIndex: 2, display: 'flex', flexDirection: 'column' }}>
        <b>It's time</b>
        <b>to evolve!</b>
      </h1>


      <p>
        We are committed to delivering <br />
        your product with the best quality ever.
      </p>

      <LinkButton
        style={{ marginTop: '24px' }}
        onClick={scrollToContactForm}
        className="presentation-button"
        size="large"
      >
        Get a free consultation
      </LinkButton>
    </div>
  )
}

export default PresentationSign
