import React from 'react'
import './LinkButton.css'

const LinkButton = React.forwardRef(
  ({ href, className = '', style, onClick, children, ariaLabel, variant = 'dark', size = 'medium' }, ref) => {
    const handleClick = (event) => {
      if (!href) {
        event.preventDefault()
      }
      if (onClick) {
        onClick(event)
      }
    }

    return (
      <a
        ref={ref}
        href={href || '#'}
        // tabIndex={href ? undefined : '0'}
        className={`a11y-link a11y-link-${size} a11y-link-${variant} ${className}`}
        style={{ ...style }}
        onClick={handleClick}
        target={href ? '_blank' : '_self'}
        rel={href ? 'noopener noreferrer' : ''}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    )
  }
)

export default LinkButton
