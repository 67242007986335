import './SkipLink.css'

export const SkipLink = () => {
  const handleScrollAndFocus = (selector) => {
    let element
    if (selector === 'main') {
      element = document.querySelector('main h1')
    } else if (selector === 'footer') {
      element = document.getElementById('skip-footer')
    }

    if (!element) {
      console.warn(`Element not found with selector: ${selector}`)
      return
    }

    element.focus()
  }

  return (
    <div className="skip__link">
      <a onClick={() => handleScrollAndFocus('main')} href="#">
        Skip to content
      </a>
      <span className="separator">-</span>
      <a onClick={() => handleScrollAndFocus('footer')} href="#">
        Skip to footer
      </a>
    </div>
  )
}
