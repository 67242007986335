import React, { useEffect, useRef } from "react";
import Button from "../Button/Button";
import "./PaymentDialog.css";
function PaymentDialog({ isOpen, closeDialog, onClick }) {
  const firstButtonRef = useRef(null);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isOpen) {
        closeDialog();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    if (isOpen && firstButtonRef.current) {
      firstButtonRef.current.focus();
    }
    var dialog = document.querySelector("#disclaimer-dialog");
    trapFocus(dialog);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function trapFocus(element) {
    var focusableEls = element.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
    );
    console.log("Dialog Element", focusableEls, element);
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;

    element.addEventListener("keydown", function (e) {
      var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

      if (!isTabPressed) {
        return;
      }
      /* shift + tab */
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        /* tab */
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }
  return (
    <div id="disclaimer-dialog">
      {isOpen && (
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="dialog-title"
          className="dialog-overlay"
        >
          <div className="dialog">
            <button
              ref={firstButtonRef}
              onClick={closeDialog}
              className="close-button"
              aria-label="Close dialog"
            >
              X
            </button>
            <h2 id="dialog-title">Disclaimer</h2>
            <p>
              You are now leaving{" "}
              <a href="http://a11ysolutions.com">http://a11ysolutions.com</a>.
              You are being redirected to a secure payment gateway provided by
              Stripe. Stripe is a third-party payment processor not affiliated
              with A11YSOLUTIONS LLC. Please review Stripe's terms and
              conditions and privacy policy before making a payment.
              A11YSOLUTIONS LLC is not responsible for any errors or omissions
              in the information provided by Stripe. If you have any questions
              about your payment, please contact Stripe directly.
            </p>
            <div className="dialog-footer">
              <Button onClick={closeDialog} variant="dark" >
                Cancel
              </Button>
              <Button onClick={onClick} variant="light" >
                Accept
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentDialog;
