import React, { useEffect } from 'react'
import './Sitemap.css'
import openIcon from '../../assets/open-icon.svg'
import { Helmet } from 'react-helmet'

function Sitemap() {
  useEffect(() => {
    const layout = document.querySelector('.layout-content')
    const topCircles = document.querySelector('.circles-container')

    layout.style.background = 'white'
    topCircles.style.display = 'none'
  }, [])

  return (
    <div id="sitemap-container">
      <Helmet>
        <title>Sitemap | A11ysolutions</title>
      </Helmet>

      <h1 tabIndex="-1">Sitemap</h1>

      <div className="sitemap-listing">
        <ul>
          <li>
            <a href="/#our-services">Services</a>
            <ul>
              <li>
                <a href="/accessibility">Accessibility</a>
              </li>
              <li>
                <a href="/functional">Functional</a>
              </li>
              <li>
                <a href="/automation">Automation</a>
              </li>
            </ul>
          </li>

          <li>
            <a href="/standards#compliance-section">Standards</a>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ada.gov/"
                  aria-label=" Americans with Disabilities Act (ADA) (opens new window)"
                >
                  Americans with Disabilities Act (ADA)
                  <img src={openIcon} alt="" className="openIcon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.section508.gov/manage/laws-and-policies/"
                  aria-label="Section 508 Amendment to the Rehabilitation Act (opens new window)"
                >
                  Section 508 Amendment to the Rehabilitation Act
                  <img src={openIcon} alt="" className="openIcon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.w3.org/TR/WCAG22/"
                  aria-label="Web Content Accessibility Guidelines (WCAG) 2.2 (opens new window)"
                >
                  Web Content Accessibility Guidelines (WCAG) 2.2
                  <img src={openIcon} alt="" className="openIcon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://digital-strategy.ec.europa.eu/en/policies/latest-changes-accessibility-standard"
                  aria-label="EN 301 549 European Standard (opens new window)"
                >
                  EN 301 549 European Standard
                  <img src={openIcon} alt="" className="openIcon" />
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a href="#name">Get a free consultation</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sitemap
